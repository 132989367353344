<template>
  <vz-dialog
    title="Нашли ошибку?"
    :loader="loader"
    name="personal-feedback"
    big
  >
    <vz-form ref="vzform" v-model="isValid">
      <p class="mb-30">
        Или у Вас есть предложение по улучшению работы личного кабинета?<br>
        Напишите нам, и мы ответим на указанную Вами почту.
      </p>
      <vz-warning-text class="mb-20">
        Обратите внимание, это окно технической поддержки! Указывайте информацию только по работе этого сайта или мобильного приложения.
        Для получения документов свяжитесь с менеджером по телефону или в чате.
      </vz-warning-text>
      <vz-textarea
        v-model="text"
        placeholder="Ваше сообщение"
        :rules="[formRules.required, {...formRules.minLength, param: 20}]"
        rows="6"
      />
    </vz-form>
    <template #footer>
      <vz-button
        variant="primary"
        :disabled="!isValid"
        class="mx-auto"
        @click="submit"
      >
        Отправить
      </vz-button>
    </template>
  </vz-dialog>
</template>

<script setup lang="ts">
import VzDialog from '~/components/ui-kit/vz-dialog.vue';
import VzForm from '~/components/ui-kit/vz-form.vue';
import VzTextarea from '~/components/ui-kit/vz-textarea.vue';
import VzButton from '~/components/ui-kit/vz-button.vue';
import {createTicket} from '~/api/contacts';
import formRules from '~/common/vz-form-rules';
import VzWarningText from '~/components/ui-kit/vz-warning-text.vue';

const {$hideDialog, $showDialog, $showToast} = useNuxtApp()

const vzform = ref()

const loader = ref(false)
const isValid = ref(false)
const text = ref('')

async function submit() {
  loader.value = true
  if (await vzform.value.validate()) {
    await createTicket(text.value)
      .then(() => {
        $hideDialog({ name: 'personal-feedback' })
        $showDialog({ name: 'vz-feedback-success-modal' })
      })
      .catch((e) => {
        $showToast({
          title: e.response?._data?.message,
          variant: 'error'
        })
      })
      .finally(() => loader.value = false)
  }
}
</script>
