<template>
  <nuxt-link
    v-bind="locationAttr"
    class="vz-personal-menu-item"
    :class="itemClass"
  >
    <div class="vz-personal-menu-item-image">
      <vz-icon
        :url="props.value.icon"
        no-masked-icon
      />
    </div>
    <div class="vz-personal-menu-item-text">
      <span>{{ title }}</span>
    </div>
    <client-only>
      <div v-if="props.value.hasPoint" class="vz-personal-menu-item-point" />
    </client-only>
    <div class="vz-personal-menu-item-additional">
      <slot />
    </div>
  </nuxt-link>
  <div v-if="value.children" class="vz-personal-menu-item-children">
    <vz-personal-menu-item
      v-for="item in value.children"
      :key="JSON.stringify(item)"
      :value="item"
    />
  </div>
</template>

<script setup lang="ts">
import { WorkSpaceItem } from '~/types/components/personal/header'
import VzIcon from '~/components/ui-kit/vz-icon.vue'
import {PropType} from 'vue';
import $breakpoints from '~/composables/breakpoints';
import {useMounted} from '@vueuse/core';

const props = defineProps({
  value: {
    type: Object as PropType<WorkSpaceItem>,
    default: () => {}
  },
  small: {
    type: Boolean,
    default: false
  }
})

const isMounted = useMounted()

const locationAttr = computed(() => {
  return {
    href: props.value.href,
    to: props.value.to,
    target: props.value?.target,
  }
})

const itemClass = computed(() => {
  return {
    active: props.value?.active || false,
    small: props.small,
    'sm-only': !!props.value.smOnly
  }
})

const title = computed(() => {
  if (!isMounted.value) {
    return props.value.title
  }
  return $breakpoints.width >= 1366 ? props.value.title : (props.value.shortTitle || props.value.title)
})
</script>
