<template>
  <div class="vz-personal-header">
    <div class="vz-personal-container">
      <vz-icon
        name="menu"
        variant="primary"
        title="Меню"
        class="vz-personal-header-navbar-icon vz-personal-header-menu"
        @click="emit('toggle')"
      />
      <nuxt-link class="vz-personal-header-logo text-decoration-none" href="/" no-prefetch>
        <img src="/svg/logo/vz-logo-white.svg">
        <div>Перевозки сборных грузов</div>
      </nuxt-link>
      <lottery-button
        text="Розыгрыш призов"
        toName="giveaway"
        dateFrom="2024/08/01"
        dateTo="2024/11/30"
        class="vz-personal-header-lottery"
      />

      <lottery-button
        text="Мерч за бонусы"
        toName="merch-for-bonuses"
        class="vz-personal-header-lottery"
      />
      <div class="vz-personal-header-navbar">
        <div class="vz-personal-header-navbar-mobile">
          <vz-dropdown-menu
              :items="moreItems"
              class="vz-personal-header-more"
              hide-toggle
          >
            <template #title>
              <vz-icon
                  size="20"
                  name="dots-horizontal"
                  variant="primary"
              />
            </template>
          </vz-dropdown-menu>
          <vz-icon
            url="/svg/vz-message-processing.svg"
            variant="primary"
            class="vz-personal-header-navbar-icon"
            title="Открыть чат"
            size="24"
            @click="openChat"
          />
        </div>
        <vz-geo-position class="vz-personal-header-navbar-desktop" />
        <vz-call class="vz-personal-header-navbar-desktop" />
        <vz-color-mode class="vz-personal-header-navbar-desktop" />
        <vz-personal-feedback class="vz-personal-header-navbar-desktop" />
        <vz-notifications class="vz-personal-header-navbar-desktop" />
        <vz-dropdown-menu
          class="flex vz-personal-header-user"
          :title="username"
          :items="settingItems"
          @click.alt.ctrl="testMode"
        />
      </div>
    </div>
    <vz-region-modal />
    <vz-feedback-call-modal />
    <vz-personal-feedback-modal />
    <vz-feedback-message-modal department-code="clientQuestion" />
    <vz-success-modal name="vz-feedback-success-modal" text="Ваше сообщение<br>отправлено.<br>Спасибо! " />
  </div>
</template>

<script setup lang="ts">
import {useUserStore} from '~/stores/personal/user';
import {storeToRefs} from 'pinia';
import VzGeoPosition from '~/components/personal/header/vz-geo-position.vue';
import VzCall from '~/components/personal/header/vz-call.vue';
import VzNotifications from '~/components/personal/header/vz-notifications.vue';
import VzDropdownMenu from '~/components/personal/header/vz-dropdown-menu.vue';
import VzPersonalFeedback from '~/components/personal/header/vz-personal-feedback.vue';
import VzColorMode from '~/components/ui-kit/vz-color-mode.vue';
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import $breakpoints from '~/composables/breakpoints'
import VzRegionModal from '~/components/modals/vz-region-modal.vue';
import VzFeedbackCallModal from '~/components/modals/vz-feedback-call-modal.vue'
import VzFeedbackMessageModal from '~/components/modals/vz-feedback-message-modal.vue'
import VzPersonalFeedbackModal from '~/components/modals/vz-personal-feedback-modal.vue';
import VzSuccessModal from '~/components/modals/vz-success-modal.vue';
import {useColorMode, useWindowSize} from '@vueuse/core';
import {isDevServer} from '~/composables/useVzFetch';
import LotteryButton from '~/components/public/lottery/lottery-button.vue';

const emit = defineEmits(['toggle'])

const { username } = storeToRefs(useUserStore())
const { $showToast, $showDialog } = useNuxtApp()
const colorMode = useColorMode({ storageKey: 'theme', modes: { light:'', dark: 'dark' } })
const { width } = useWindowSize()

const isMobile = computed(() => {
  return width.value < 640
})

const settingItems = [
  {
    title: 'Настройки',
    icon: 'cog',
    to: { name: 'personal-account' }
  },
  {
    title: 'Выйти',
    icon: 'logout',
    to: { name: 'personal-logout' },
  }
]

const moreItems = computed(() =>[
  {
    title: 'Город',
    icon: 'map-marker',
    onClick: () => {
      $showDialog({name: 'region'})
    }
  },
  {
    title: 'Обратная связь',
    icon: 'phone',
    onClick: () => {
      $showDialog({ name: 'feedback-message' })
    }
  },
  {
    title: 'Сообщить об ошибке',
    icon: 'alert',
    onClick: () => {
      $showDialog({ name: 'personal-feedback' })
    }
  },
  {
    title: 'Цветовая схема',
    icon: iconName.value,
    onClick: onChangeColorMode
  },
])

const isLightMode = computed(() => {
  return colorMode.value === 'light'
})

const iconName = computed(() => {
  return isLightMode.value ? 'white-balance-sunny' : 'weather-night'
})

function onChangeColorMode() {
  colorMode.value = isLightMode.value ? 'dark': 'light'
}

function testMode() {
  if (isDevServer) {
    const config = useRuntimeConfig()
    if (config.public.TEST) {
      config.public.TEST = undefined
      config.public.TEST_REQ = undefined
      config.public.TEST_CASE = undefined
      $showToast({ title: 'Режим тестирования выключен' })
    } else {
      config.public.TEST = true
      config.public.TEST_REQ = [
        'https://api.vozovoz.dev/v3/order/timetable',
        'https://api.vozovoz.dev/v2/order/get-price',
        'https://api.vozovoz.dev/v2/personal/order-list/get'
      ]
      config.public.TEST_CASE = 'edit'
      $showToast({ title: 'Режим тестирования включен' })
    }
  }
}

function openChat() {
  console.log('openChat')
  window.postMessage({
    type: 'open-chat',
  }, '*')
}
</script>
