<template>
  <div v-on-click-outside="hide" class="vz-dropdown-button-new vz-notifications">
    <vz-icon
      ref="button"
      rounded
      big
      no-masked-icon
      url="/svg/icon/vz-bell.svg"
      variant="primary"
      title="События и уведомления"
      size="24"
      @click="toggleShowList"
    >
      <client-only>
        <div v-if="newItemsLength" class="vz-notifications-count">
          {{ newItemsLength }}
        </div>
      </client-only>
    </vz-icon>
    <div
      v-if="isShowList"
      v-scroll="calculatePosition"
      class="vz-dropdown-button-new-list vz-card-light-shadow"
      :style="listStyle"
    >
      <nuxt-link
        v-for="(item, index) of newsStore.lastEvents"
        :key="index"
        class="vz-dropdown-button-new-list-item"
        :class="itemClass(item)"
        @click="hide"
        @mouseenter="onMouseEnter(item)"
      >
        <h5>{{ item.title }}</h5>
        <span class="item-title">{{ item.announce }}</span>
      </nuxt-link>
      <div class="vz-notifications-append">
        <vz-button variant="primary" big :to="{ name: 'personal' }">
          События
        </vz-button>
        <vz-button big :to="{ name: 'personal', query: {tab: 'offers'} }">
          Уведомления
        </vz-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import VzIcon from '~/components/ui-kit/vz-icon.vue';
import {storeToRefs} from 'pinia';
import {useUserStore} from '~/stores/personal/user';
import VzButton from '~/components/ui-kit/vz-button.vue';
import {VzClass} from '~/types/types';
import {EventRecord} from '~/types/components/personal/actions';
import {useNewsStore} from '~/stores/news';
import {getDomain} from '~/common/functions';
import {cookieItems} from '~/composables/useVzFetch';

const { unreadNewsCount } = storeToRefs(useUserStore())
const newsStore = useNewsStore()
const hoveredIds = useCookie<string[]>(
  cookieItems.viewedEvents,
  { maxAge: 1000000, sameSite: true, secure: true, domain: getDomain(), decode: (str) => str.split(', ') || [], encode: (ids) => ids?.join(', ') }
)

function hoverEvent(id: string) {
  if (!hoveredIds.value) {
    hoveredIds.value = []
  }
  hoveredIds.value.unshift(id)
  hoveredIds.value = hoveredIds.value.slice(0, 3) // 3 последних
}

const isShowList = ref(false)
const listStyle = ref({})
const button = ref<{ $el: HTMLElement } | null>(null)

const eventCount = computed(() => {
  return newsStore.lastEvents.filter(e => !hoveredIds.value.includes(e.id.toString())).length
  // return unreadNewsCount.value + unreadCount.notification
  return unreadNewsCount.value
})

const newItemsLength = computed(() => {
  if (!hoveredIds.value?.length) {
    return newsStore.lastEvents.length
  }
  return eventCount.value
})

watch(() => useRoute().path, hide)

onMounted(() => {
  newsStore.fetchEvents()
})

function hide() {
  isShowList.value = false
}

function itemClass(item: EventRecord): VzClass {
  return { 'is-new': !hoveredIds.value?.includes(item.id.toString()) }
}

function calculatePosition(): void {
  const rect = button.value?.$el.getBoundingClientRect()
  const docWidth = document.documentElement.clientWidth || document.body.clientWidth

  if (rect) {
    listStyle.value = {
      top: (rect.top + rect.height) + 'px',
      minWidth: `${rect.width}px`,
      right: (docWidth - rect.right) + 'px',
      left: 'unset'
    }
  }
}

function toggleShowList(): void {
  isShowList.value = !isShowList.value
  if (isShowList.value) {
    calculatePosition()
  }
}

function onMouseEnter(item: EventRecord): void {
  const id = item.id
  if (!hoveredIds.value?.includes(id.toString())) {
    hoverEvent(id.toString())
  }
}
</script>

<style lang="scss" scoped>
.vz-notifications {
  .vz-dropdown-button-new-list {
    width: 358px;
    &-item {
      display: block;
      padding: 10px 20px;
      color: $font-dark;
      &:first-child {
        padding-top: 20px;
        border-radius: 6px 6px 0 0;
      }

      h5 {
        font-size: 16px;
        color: $primary;
        margin-bottom: 8px;
      }
      &:hover {
        text-decoration: none;
      }
      &.is-new {
        background-color: $notification;
      }
    }
  }

  &-append {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px 10px 25px;
    .vz-button {
      margin: 5px 10px;
    }
  }

  &-count {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    color: $white;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    right: 9px;
    font-size: 11px;
    width: 16px;
    height: 16px;
    font-weight: 400;
  }

  @media screen and (max-width: $sm) {
    .vz-dropdown-button-new-list {
      width: 240px;
    }
  }
}
</style>
