<template>
  <div class="vz-personal-sidebar-menu">
    <div class="vz-personal-sidebar-menu-overlay" :class="menuOpenClass" @click="emit('toggle')" />
    <div class="vz-card-light-shadow vz-personal-sidebar-menu-items" :class="menuOpenClass">
      <div v-for="item in menu" :key="`${JSON.stringify(item)}`" @click="onItemClick">
        <vz-personal-menu-item
          v-if="!item.hidden"
          :value="item"
        >
          <client-only>
            <div v-if="item.hasPoint" class="promo-badge" />
          </client-only>
        </vz-personal-menu-item>
      </div>

      <div class="vz-personal-sidebar-menu-control">
        <vz-icon
          :name="menuControlIconName"
          rounded
          variant="white-gray-ws"
          class="vz-cursor-pointer"
          @click="emit('toggle')"
        />
      </div>
      <div class="vz-personal-sidebar-menu-items-mobile" @click="onMenuClick" />
    </div>
    <client-only>
      <vz-personal-discount-promo-card
        v-if="discountData"
        v-bind="discountData"
        class="ml-5"
      />
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { vzWorkSpaceMenu } from '~/common/personal/menu-items'
import { useLayoutStore } from '~/stores/personal/layout'
import { WorkSpaceItem } from '~/types/components/personal/header'
import { useWindowSize, useMounted } from '@vueuse/core'
import { useUserStore } from '~/stores/personal/user'
import { storeToRefs } from 'pinia'
import { VzClass } from '~/types/types';
import { watch } from 'vue';
import VzPersonalMenuItem from '~/components/personal/header/vz-personal-menu-item.vue';
import VzIcon from '~/components/ui-kit/vz-icon.vue'
import {useOrderListStore} from '~/stores/personal/order-list';
import VzPersonalDiscountPromoCard from '~/components/personal/discount/vz-personal-discount-promo-card.vue';
import { useDayjs } from '#dayjs'

const emit = defineEmits(['toggle'])

const dayjs = useDayjs()
const { menuCollapsed } = storeToRefs(useLayoutStore())
const { setCollapsedMenu } = useLayoutStore()
const { width } = useWindowSize()
const {
  unreadNewsCount, unreadPromoCount, unreadPromoSourceCount, unreadTaskCount, personalDiscount
} = storeToRefs(useUserStore())
const { lastCurrentTab, defaultTab } = storeToRefs(useOrderListStore())
const isMounted = useMounted()

const menu = computed((): WorkSpaceItem[] => {
  const routeName = useRoute().name as string

  if (!routeName) {
    return vzWorkSpaceMenu
  }

  return vzWorkSpaceMenu.map((menuItem) => {
    menuItem.active = routeName === `personal-${menuItem.name}`

    switch (menuItem.name) {
      case 'order': {
        menuItem.active = routeName === 'personal-order-create'
        break
      }
      case 'events': {
        menuItem.active = routeName === 'personal'
        break
      }
      case 'orders': {
        menuItem.active = (routeName.includes('personal-order') && !routeName.includes('template')) || routeName.includes('multi-pickup')
        if (routeName === 'personal-order-create' && width.value <= 640) {
          menuItem.active = false
        }
        const currentTab = lastCurrentTab.value?.tabCode || defaultTab.value?.tabCode ||
          lastCurrentTab.value?.tabId || defaultTab.value?.tabId

        if (currentTab) {
          menuItem.to = { name: 'personal-orders-tab', params: { tab: currentTab } }
        }
        break
      }
      case 'contractors': {
        menuItem.active = routeName.includes('personal-contractors')
        break
      }
      case 'orders-templates': {
        menuItem.active = routeName.includes('template')
        break
      }
      case 'promocodes': {
        menuItem.hasPoint = !!(unreadPromoCount.value || unreadPromoSourceCount.value)
        break
      }
      case 'discount': {
        menuItem.hasPoint = !!(personalDiscount.value?.length)
        menuItem.hidden = !isMounted.value || !(personalDiscount.value?.length)
        break
      }
      case 'bonus': {
        menuItem.hasPoint = unreadTaskCount.value
        menuItem.active = routeName === 'personal-bonus-tab'
        break
      }
    }

    return menuItem
  })
})

const menuOpenClass = computed((): VzClass => {
  return menuCollapsed.value  ? 'menu-open' : ''
})

const menuControlIconName = computed(() => {
  return 'chevron-' + (menuCollapsed.value ? 'left' : 'right')
})

const discountData = computed(() => {
  const filtered = personalDiscount.value?.filter(pd => pd.suggest !== false) || []
  if (!filtered.length) {
    return null
  }
  // Берём текущий день в году и высчитываем какую акцию из списка показать, и с каким стилем
  // Остаток от деление каждый день смещается на единичку
  // Так сделано чтобы каждый день показывалось по новому
  const date = dayjs().dayOfYear()
  const cardVariantLength = 9
  const discountIndex = date % filtered.length
  const variant = date % cardVariantLength
  // console.log(personalDiscount.value?.length, {date, discountIndex, variant})
  return {
    discount: filtered[discountIndex],
    variant
  }
})

function onMenuClick(e: any) {
  if (width.value <= 1024 && !menuCollapsed.value) {
    emit('toggle')
  }
}

function onItemClick() {
  if (menuCollapsed.value) {
    emit('toggle')
  }
}

watch(() => width.value, (value, oldValue): void => {
  if (process.client && width.value <= 768 && menuCollapsed.value && oldValue > 768) {
    setCollapsedMenu(false)
  }

}, { immediate: true })
</script>

<style lang="scss" scoped>
.menu-open {
  .promo-badge {
    display: none !important;
  }
}
.promo-badge {
  display: none;
}
@media screen and (max-width: 1024px) {
  .promo-badge {
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    left: 20px;
    top: 40%;
    transform: translateY(-50%);
    background-color: $primary;
    border-radius: 50%;
  }
}
</style>
