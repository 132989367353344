<template>
  <vz-dialog
    name="offer"
    class="vz-offer-modal"
    hideHeader
    hideOffset
    needClose
    big
    @hide="onHide"
    @showed="onShow"
  >
    <vz-offer-card
      v-if="firstOffer"
      :offer="firstOffer"
      class="p-0"
    />

    <template #footer>
      <div class="vz-offer-modal-footer">
        <vz-button
          variant="primary"
          @click="notInterest(firstOffer.guid)"
        >
          Ознакомился
        </vz-button>
      </div>
    </template>
  </vz-dialog>
</template>

<script setup lang='ts'>
import VzOfferCard from '~/components/personal/actions/vz-offer-card.vue';
import {Timeout} from '~/types/types';
import {PersonalOffer} from '~/types/components/personal/actions';
import {getOfferList, updateOffer} from '@/api/event';
import {storeToRefs} from 'pinia';
import {useBackendStore} from '~/stores/backend';

const { $showToast, $showDialog, $hideDialog } = useNuxtApp()

const selected = ref(0)
const showTimeOut = ref<Timeout | null>(null)
const offers = ref<PersonalOffer[]>([])
const notInterestedStorage = useCookie('offers-not-interested', { maxAge: 6000 })
const { backendAvailable } = storeToRefs(useBackendStore())
let readOfferIds: Set<number|string> = new Set()

const firstOffer = computed(() => {
  return offers.value[0]
})

async function notInterest(guid: string) {
  await updateOffer(guid, { notInterested: true })
  await loadOffers()
  // Если немного предложений, то показываем сразу, иначе по таймауту
  if (offers.value.length === 0 || offers.value.length > 1) {
    hide()
  }
}

function onHide() {
  // readOfferIds.forEach(offerRead)
  updateOffer(firstOffer.value.guid)
  notInterestedStorage.value = 'true'

  if (offers.value.length > 0) {
    let minTimeout = Number.MAX_SAFE_INTEGER
    let index = 0
    offers.value.forEach((offer, i) => {
      const timeout = +offer.autoShowDelayMinutes * 1000 * 60
      if (timeout < minTimeout) {
        minTimeout = timeout
        index = i
      }
    })

    showTimeOut.value = setTimeout(() => {
      show()
      selected.value = index
    }, minTimeout)
  }
}

function onShow() {
  if (showTimeOut.value) {
    clearTimeout(showTimeOut.value)
  }
  selected.value = 0
  if (offers.value.length) {
    readOfferIds = new Set([offers.value[0].guid])
  } else {
    readOfferIds = new Set()
  }
}

async function loadOffers () {
  let offerList = await getOfferList()
  offerList = offerList
    .filter(offer => !offer.notInterested)
    .filter(offer => offer.autoShowTimes > offer.timesShown)

  offers.value = offerList
  selected.value = 0
}

function show() {
  if (firstOffer.value) {
    $showDialog({ name: 'offer' })
  }
}

function hide() {
  $hideDialog({ name: 'offer' })
}

watch(() => selected.value,
  (value) => {
    readOfferIds.add(offers.value[value].guid)
  }
)

onMounted(async () => {
  await loadOffers()
  if (!notInterestedStorage.value && backendAvailable.value) {
    if (offers.value.length > 0) {
      show()
    }
  }
})
</script>

<style lang="scss">
.vz-offer-modal {
  width: 640px !important;

  &-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .vz-button {
      height: 40px;
      font-size: 14px;
    }
  }

  .vz-dialog-card-content-wrapper {
    padding: 0;
  }

  .vz-dialog-card-buttons {
    border-top: unset !important;
    padding: 24px 40px !important;
  }

  @media screen and (max-width: 640px) {
    width: 100% !important;
    max-width: 100% !important;
    border-radius: unset;
    height: 100vh;
    top: 0;
    flex-direction: column;

    .vz-dialog-card-content {
      flex: 1 0 auto;
      width: inherit;
    }

    .vz-dialog-card-buttons {
      flex-direction: column;
      flex: unset;
    }
  }
}
</style>
