<template>
  <div class="vz-personal-footer">
    <div class="vz-personal-container">
      <div class="vz-personal-footer-content">
        <div class="vz-personal-footer-wrapper">
          <div class="vz-personal-footer-address">
            © 2014 - {{ year }}, ТK "Возовоз"
            <div>{{ address }}</div>
          </div>
          <div class="vz-personal-footer-sn">
            <nuxt-link :href="vkLink" target="_blank">
              <vz-icon
                no-masked-icon
                url="/svg/vz-vk.svg"
                size="32"
              />
            </nuxt-link>
            <nuxt-link :href="okLink" target="_blank">
              <vz-icon
                no-masked-icon
                url="/svg/vz-ok.svg"
                size="32"
              />
            </nuxt-link>
            <nuxt-link :href="tgLink" target="_blank">
              <vz-icon
                no-masked-icon
                url="/svg/vz-tg.svg"
                size="32"
              />
            </nuxt-link>
          </div>
          <div class="vz-personal-footer-app">
            <nuxt-link
              :href="androidApk"
              target="_blank"
            >
              <img src="/svg/vz-android-full.svg">
            </nuxt-link>
            <nuxt-link
              :href="telegramBot"
              target="_blank"
            >
              <img src="/svg/vz-tg-bot.svg">
            </nuxt-link>
            <nuxt-link
              :href="googleApp"
              target="_blank"
            >
              <img src="/svg/vz-gp-full.svg">
            </nuxt-link>
            <nuxt-link
              :href="appleApp"
              target="_blank"
            >
              <img src="/svg/vz-apple-full.svg">
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { appleApp, googleApp, telegramBot, androidApk, okLink, vkLink, tgLink } from '@/api/constant-urls'
import {useDomainCountry} from '~/composables/states';

const domainCountry = useDomainCountry()

const year = computed(() => {
  return new Date().getFullYear()
})

const address = computed(() => {
  switch (domainCountry.value) {
    case 'RU':
      return '194292, Санкт-Петербург, 6-й Верхний переулок дом 12 литер А, кабинет №210'
    case 'BY':
      return '220075, Республика Беларусь, г.Минск, Промышленный переулок 12, каб.15'
    case 'KZ':
      return 'АЗ5С1У2, Казахстан, город Алматы, Турксибский район, Илийский Тракт, дом 11'
    case 'KG':
      return 'г.Бишкек ул.Исакеева 13/1'
    default:
      return ''
  }
})
</script>
