<template>
  <div class="offer-card">
    <img
      v-if="offer.imageUrl"
      :src="offer.imageUrl"
      alt=""
      class="offer-card-image"
    >

    <div class="offer-card-data">
      <div class="offer-card-data-header">
        <div class="offer-card-data-header-date">
          <div v-for="(date, i) in dateList" :key="i" class="offer-card-date">
            <span>{{ date.date }}</span>
          </div>
        </div>

        <h6 class="mb-15">
          {{ title }}
        </h6>
      </div>

      <div class="offer-card-data-text">
        <span v-html="text" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {PropType} from 'vue';
import {PersonalOffer} from '~/types/components/personal/actions';

interface IDate {
  icon: string
  date: string
}

const props = defineProps({
  offer: {
    type: Object as PropType<PersonalOffer>,
    default: () => ({})
  }
})

const title = computed(() => {
  return props.offer.title || props.offer.name
})

const text = computed(() => {
  return props.offer.announce
})

const dateList = computed(() => {
  const dates: IDate[] = []

  if (props.offer.startedAt) {
    dates.push({
      icon: '/svg/vz-notification-grey.svg', // bell-fill
      date: formatDate(props.offer.startedAt)
    })
  }

  return dates
})

function formatDate(dateNumeric: number | string): string {
  const date = new Date(dateNumeric)
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}
</script>

<style lang="scss" scoped>
@use "assets/sections/personal/actions/vz-offer-card";
</style>
