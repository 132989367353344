<template>
  <div v-on-click-outside="onClickOutside" class="vz-dropdown-menu" :class="{ hideToggle }">
    <div class="vz-dropdown-menu-title" @click="onShowList">
      <slot name="title">
        <vz-icon name="account" :size="16" class="vz-dropdown-menu-title-icon" />
        <span>{{ title }}</span>
      </slot>
    </div>
    <div
      v-show="isShowList"
      class="vz-dropdown-menu-list"
    >
      <nuxt-link
        v-for="{to, title, icon, iconUrl, onClick} of items"
        :to="to"
        class="vz-dropdown-menu-list-item"
        @click="onClickOutside, onClick?.()"
      >
        <vz-icon no-masked-icon :name="icon" :url="iconUrl" :size="14" />
        <span class="item-title">{{ title }}</span>
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import VzIcon from '~/components/ui-kit/vz-icon.vue';

const router = useRouter()
const props = defineProps({
  hideToggle: {
    type: Boolean,
    default: false
  },
  title: {
    default: ''
  },
  items: {
    type: Array,
    default: () => []
  }
})

let isShowList = ref(false)

const onClickOutside = (): void => {
  if (isShowList.value) {
    isShowList.value = false
  }
}

const onShowList = (): void => {
  isShowList.value = !isShowList.value
}
</script>