<template>
  <div class="vz-call">
    <vz-dropdown>
      <template #button>
        <div class="vz-call-wrapper">
          <vz-icon
            name="phone"
            variant="white"
            size="16"
          />
          <span class="vz-call-text">{{ selectedPhone }}</span>
          <vz-icon
            name="chevron-down"
            variant="white"
            padding="0"
          />
        </div>
      </template>
      <template #content>
        <a :href="`tel:${selectedPhone}`" class="vz-call-content-item">
          <vz-icon
            name="arrow-top-right"
            size="14"
            padding="0"
          />
          <span>Позвонить</span>
        </a>
        <div class="vz-call-content-item" @click="openVzFeedbackMessageModal">
          <vz-icon
            name="arrow-bottom-left"
            size="14"
            padding="0"
          />
          <span>Обратная связь</span>
        </div>
      </template>
    </vz-dropdown>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia';
import {useCityStore} from '~/stores/city';

const { $showDialog } = useNuxtApp()
const { currentPhones } = storeToRefs(useCityStore())

const selectedPhone = computed(() => {
  return currentPhones.value?.[0]
})

function openVzFeedbackMessageModal (): void {
  $showDialog({ name: 'feedback-message' })
}
</script>
