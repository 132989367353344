<template>
  <vz-card
    class="vz-personal-discount-promo-card vz-card-no-border"
    :class="`vz-personal-discount-promo-card-${computedVariant}`"
  >
    <div class="vz-personal-discount-promo-card-title">
      {{ discount.service }}
    </div>
    <div v-if="discount.params" class="vz-personal-discount-promo-card-path">
      <div v-if="discount.params?.dispatch?.name">
        <vz-icon
          name="map-marker"
          size="16"
        />
        <span :title="discount.params.dispatch.name">{{ discount.params.dispatch.name }}</span>
      </div>
      <div v-if="discount.params?.destination?.name">
        <vz-icon
          name="map-marker"
          size="16"
        />
        <span :title="discount.params.destination.name">{{ discount.params.destination.name }}</span>
      </div>
    </div>
    <div
      class="vz-personal-discount-promo-card-value"
      :class="{'vz-personal-discount-promo-card-value-empty' : !discount.discountValue}"
    >
      <span class="vz-personal-discount-promo-card-value-value">
        {{ discount.discountValue }}
      </span>
      <span class="vz-personal-discount-promo-card-value-type">{{ discount.discountType }}</span>
      <span class="vz-personal-discount-promo-card-value-title">Скидка</span>
    </div>
    <vz-button
      big
      :to="{ name: 'personal-discount' }"
      variant="public-primary"
      :icon-name="localVariant >= 7 ? 'arrow-right' : ''"
      icon-right
      @click.alt.ctrl.prevent="localVariant = (localVariant + 1) % 9"
    >Подробнее</vz-button>
  </vz-card>
</template>

<script setup lang="ts">
import {PropType} from 'vue';
import VzCard from '~/components/ui-kit/vz-card.vue';
import {PersonalDiscount} from '~/types/components/personal/personal-discount';
import VzButton from '~/components/ui-kit/vz-button.vue';
import VzIcon from '~/components/ui-kit/vz-icon.vue';

const props = defineProps({
  discount: {
    type: Object as PropType<PersonalDiscount>,
    default: () => ({})
  },
  variant: {
    type: Number,
    default: 0
  }
})

const localVariant = ref(props.variant)

const computedVariant = computed(() => {
  if (!props.discount?.discountValue && (localVariant.value >= 4 && localVariant.value <= 6)) {
    return localVariant.value - 3
  }
  return localVariant.value
})

watch(() => props.variant, () => {
  localVariant.value = props.variant
})
</script>

<style lang="scss" scoped>
.vz-personal-discount-promo-card {
  padding: 20px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  max-width: 265px;

  transition: max-width 0.3s;

  &-0 {
    color: $pure-white;
    align-items: center;
    text-align: left;
    background-image: url("/svg/components/discount-promo/discount-title.svg");
    background-repeat: no-repeat;
    background-color: #FEE0DA;
    padding: 18px 30px 16px;

    .vz-personal-discount-promo-card {
      &-title {
        font-size: 18px;
        line-height: 21px;
        font-weight: bold;
        color: $primary;
        margin: 0 35px 2px 5px;
        order: 1;
      }

      &-path {
        color: $font-dark;
        font-size: 16px;
        line-height: 19px;
        font-weight: 900;
        text-transform: uppercase;
        order: 2;
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        row-gap: 5px;

        .vz-icon {
          color: $primary;
          margin-right: 5px;
        }
        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:before {
          content: '';
          display: block;
          grid-row: 1/3;
          background-image: url("/svg/components/discount-promo/discount-path.svg");
          width: 45px;
          height: 31px;
        }
      }

      &-value {
        display: flex;
        align-self: flex-start;
        flex-direction: column-reverse;
        color: #FEE0DA;
        order: 0;
        text-align: center;
        font-weight: 900;

        &-empty {
          visibility: hidden;
          height: 95px;
        }
        &-value {
          margin-right: 5px;
          font-size: 82px;
          line-height: 1;
        }
        &-type {
          display: none;
        }
        &-title {
          font-size: 24px;
          line-height: 26px;
          text-transform: uppercase;
        }
      }
    }

    .vz-button {
      width: 100%;
      color: $pure-white;
      background-color: $primary;
      margin-top: 3px;
      order: 3;
    }

    @media screen and (max-width: $pa-md) {
      background-image: url("/svg/components/discount-promo/discount-title-sm.svg");
      .vz-personal-discount-promo-card {
        &-title {
          margin: 0;
        }
        &-path {
          font-size: 14px;
          line-height: 16px;
          margin-left: 5px;
          &:before {
            background-image: url("/svg/components/discount-promo/discount-path-sm.svg");
            width: 20px;
          }
          .vz-icon {
            margin-right: 2px;
            margin-left: 2px;
          }
        }
        &-value {
          margin-left: 10px;
          margin-top: 0;
          text-align: left;
          &-value {
            font-size: 56px;
          }
          &-type {
            display: none;
          }
          &-title {
            font-size: 20px;
          }
        }
      }
      .vz-button {
        width: 100%;
      }
    }
  }

  &-1 {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.00) 100%), #EC1B22;
    color: $pure-white;
    align-items: center;
    text-align: center;

    .vz-personal-discount-promo-card {
      &-title {
        font-size: 19px;
        line-height: $fs-26;
        text-transform: uppercase;
        font-weight: bold;
      }

      &-path {
        border: 2px solid $pure-white;
        padding: 9px;
        font-size: 16px;
        font-weight: bolder;
        text-transform: uppercase;
        width: 100%;
      }

      &-value {
        display: grid;
        grid-template-columns: auto auto;
        margin: 2px 0;

        &-empty {
          display: none;
        }
        &-value {
          grid-row: 1 / 3;
          margin-right: 5px;
          font-size: 56px;
          line-height: 48px;
          color: $pure-white;
          font-family: 'Intro Inline', sans-serif !important;
        }
        &-type {
          text-align: left;
          font-size: 24px;
          line-height: 26px;
          font-weight: bold;
        }
        &-title {
          font-size: 20px;
          line-height: 20px;
          font-weight: bolder;
          text-transform: uppercase;
        }
      }
    }

    .vz-button {
      width: 100%;
      color: $primary;
      background-color: $pure-white;
    }

    @media screen and (max-width: $pa-md) {
      .vz-personal-discount-promo-card {
        &-value {
          &-title {
            font-size: 18px;
          }
        }
        &-title {
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
  }

  &-2 {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0.00) 100%), #1B7BEC;
    color: $pure-white;
    align-items: center;
    text-align: center;

    .vz-personal-discount-promo-card {
      &-title {
        font-size: 19px;
        line-height: $fs-26;
        text-transform: uppercase;
        font-weight: 900;
        order: 1;
        border: 2px solid $pure-white;
        border-radius: 4px;
        padding: 15px 15px 10px;
      }

      &-path {
        font-size: 16px;
        font-weight: bolder;
        text-transform: uppercase;
        order: 3;
      }

      &-value {
        display: grid;
        grid-template-columns: auto auto;
        order: 0;
        margin-top: 5px;

        &-empty {
          display: none;
        }
        &-value {
          grid-row: 1 / 3;
          margin-right: 5px;
          font-size: 68px;
          line-height: 50px;
          font-weight: 900;
          color: $pure-white;
        }
        &-type {
          text-align: left;
          font-size: 24px;
          line-height: 26px;
          font-weight: bold;
        }
        &-title {
          font-size: 22px;
          line-height: 26px;
          font-weight: bolder;
          text-transform: uppercase;
        }
      }
    }

    .vz-button {
      width: 100%;
      order: 3;
      color: #1B7BEC;
      background-color: $pure-white;
    }

    @media screen and (max-width: $pa-md) {
      .vz-personal-discount-promo-card {
        &-title {
          padding: 10px;
          font-size: 16px;
          line-height: 18px;
        }
        &-title {
          padding: 10px 8px;
        }
        &-value {
          &-value {
            font-size: 56px;
          }
          &-title {
            font-size: 18px;
          }
          &-type {
            font-size: 24px;
            line-height: 24px;
          }
        }
      }
    }
  }

  &-3 {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.00) 100%), #E31B87;
    color: $pure-white;
    align-items: center;
    text-align: center;

    .vz-personal-discount-promo-card {
      &-title {
        font-size: 19px;
        line-height: $fs-26;
        text-transform: uppercase;
        font-weight: 900;
        order: 0;
      }

      &-path {
        font-size: 16px;
        font-weight: bolder;
        text-transform: uppercase;
        text-align: left;
        order: 3;
      }

      &-value {
        display: grid;
        grid-template-columns: auto auto;
        order: 1;
        background-color: $pure-white;
        color: #E31B87;
        border-radius: 4px;
        padding: 13px 20px 7px;
        font-weight: 900;

        &-empty {
          display: none;
        }

        &-value {
          grid-row: 1 / 3;
          margin-right: 5px;
          font-size: 68px;
          line-height: 50px;
        }
        &-type {
          text-align: left;
          font-size: 24px;
          line-height: 28px;
        }
        &-title {
          font-size: 22px;
          line-height: 22px;
          text-transform: uppercase;
        }
      }
    }

    .vz-button {
      order: 3;
      width: 100%;
      color: #E31B87;
      background-color: $pure-white;
    }

    @media screen and (max-width: $pa-md) {
      .vz-personal-discount-promo-card {
        &-title {
          font-size: 16px;
          line-height: 20px;
        }
        &-value {
          padding: 10px 10px 5px;
          &-value {
            font-size: 50px;
            line-height: 48px;
          }
          &-title {
            font-size: 16px;
          }
          &-type {
            font-size: 22px;
            line-height: 25px;
          }
        }
      }
    }
  }

  &-4 {
    background-position: bottom;
    background-image: url("/images/pages/personal/terminal_image.png");
    color: $pure-white;
    align-items: center;
    text-align: center;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      width: 350px;
      height: 350px;
      transform: rotate(45deg);
      bottom: 137px;
      background-color: $primary;
      border-radius: 4px;
    }

    .vz-personal-discount-promo-card {
      &-title {
        display: none;
      }

      &-path {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        text-transform: uppercase;
        order: 0;
        z-index: 10;
        .vz-icon {
          font-size: inherit !important;
          margin-right: 2px;
        }
      }

      &-value {
        display: grid;
        grid-template-columns: auto auto;
        gap: 3px;
        order: 1;
        background-color: $pure-white;
        color: $primary;
        border-radius: 4px;
        box-shadow: 0 0 0 4px $primary;
        padding: 17px 16px;
        margin-top: 5px;
        z-index: 10;

        &-empty {
          visibility: hidden;
        }

        &-value {
          grid-row: 1 / 3;
          font-size: 78px;
          line-height: 0.9;
          font-family: 'Intro Inline', sans-serif !important;
        }
        &-type {
          text-align: left;
          font-size: 26px;
          line-height: 24px;
          font-weight: 900;
          margin-top: auto;
        }
        &-title {
          font-size: 22px;
          line-height: 26px;
          font-weight: 900;
          text-transform: uppercase;
        }
      }
    }

    .vz-button {
      order: 3;
      width: 100%;
      color: $pure-white;
      background-color: $primary;
      border-color: $primary;
      margin-top: 25px;
    }

    @media screen and (max-width: $pa-md) {
      .vz-personal-discount-promo-card {
        &-path {
          font-size: 16px;
          line-height: 24px;
        }
        &-value {
          gap: 0;
          &-value {
            font-size: 50px;
            line-height: 48px;
          }
          &-title {
            font-size: 16px;
            line-height: 16px;
          }
          &-type {
            font-size: 22px;
          }
        }
      }
    }
  }

  &-5 {
    color: $pure-white;
    align-items: center;
    text-align: center;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      width: 350px;
      height: 350px;
      transform: rotate(45deg);
      bottom: 137px;
      border-radius: 4px;
      background-color: $primary;
    }
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 30%;
      top: -5px;
      z-index: 10;
      background: linear-gradient(180deg, #5496FA 0%, transparent 50%);
    }

    .vz-personal-discount-promo-card {
      &-title {
        display: none;
      }

      &-path {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        text-transform: uppercase;
        order: 0;
        z-index: 10;
        .vz-icon {
          font-size: inherit !important;
          margin-right: 2px;
        }
      }

      &-value {
        display: grid;
        grid-template-columns: auto auto;
        gap: 3px;
        order: 1;
        background-color: $pure-white;
        color: $primary;
        border-radius: 4px;
        box-shadow: 0 0 0 4px $primary;
        padding: 17px 16px;
        margin-bottom: 15px;
        margin-top: 5px;
        z-index: 10;

        &-empty {
          visibility: hidden;
        }

        &-value {
          grid-row: 1 / 3;
          font-size: 78px;
          line-height: 0.9;
          font-family: 'Intro Inline', sans-serif !important;
        }
        &-type {
          text-align: left;
          font-size: 26px;
          line-height: 24px;
          font-weight: 900;
          margin-top: auto;
        }
        &-title {
          font-size: 22px;
          line-height: 26px;
          font-weight: 900;
          text-transform: uppercase;
        }
      }
    }

    .vz-button {
      order: 3;
      width: 100%;
      color: $pure-white;
      background-color: $primary;
      border-color: $primary;
    }

    @media screen and (max-width: $pa-md) {
      .vz-personal-discount-promo-card {
        &-path {
          font-size: 16px;
          line-height: 24px;
        }

        &-value {
          gap: 0;
          padding: 10px 10px 5px;
          &-value {
            font-size: 50px;
            line-height: 48px;
          }
          &-title {
            font-size: 16px;
            line-height: 16px;
          }
          &-type {
            font-size: 22px;
          }
        }
      }
    }
  }

  &-6 {
    color: $pure-white;
    align-items: center;
    text-align: center;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      width: 350px;
      height: 350px;
      transform: rotate(45deg);
      bottom: 137px;
      border-radius: 4px;
      background-color: $primary;
    }
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 30%;
      top: -5px;
      z-index: 10;
      background: linear-gradient(180deg, #FFE91F 0%, transparent 50%);
    }

    .vz-personal-discount-promo-card {
      &-title {
        display: none;
      }

      &-path {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        text-transform: uppercase;
        order: 0;
        z-index: 10;
        .vz-icon {
          font-size: inherit !important;
          margin-right: 2px;
        }
      }

      &-value {
        display: grid;
        grid-template-columns: auto auto;
        gap: 3px;
        order: 1;
        background-color: $pure-white;
        color: $primary;
        border-radius: 4px;
        box-shadow: 0 0 0 4px $primary;
        padding: 17px 16px;
        margin-bottom: 15px;
        margin-top: 5px;
        z-index: 10;
        font-weight: 900;

        &-empty {
          visibility: hidden;
        }

        &-value {
          grid-row: 1 / 3;
          font-size: 78px;
          line-height: 0.9;
          margin-right: 5px;
        }
        &-type {
          text-align: left;
          font-size: 26px;
          line-height: 24px;
          margin-top: auto;
        }
        &-title {
          font-size: 22px;
          line-height: 26px;
          text-transform: uppercase;
        }
      }
    }

    .vz-button {
      order: 3;
      width: 100%;
      color: $pure-white;
      background-color: $primary;
      border-color: $primary;
    }

    @media screen and (max-width: $pa-md) {
      .vz-personal-discount-promo-card {
        &-path {
          font-size: 16px;
          line-height: 24px;
        }
        &-value {
          gap: 0;
          padding: 10px 10px 5px;
          &-value {
            font-size: 50px;
            line-height: 48px;
          }
          &-title {
            font-size: 16px;
            line-height: 16px;
          }
          &-type {
            font-size: 22px;
          }
        }
      }
    }
  }

  &-7 {
    align-items: center;
    text-align: center;border-radius: 6px;
    background: linear-gradient(142deg, rgba(237, 47, 161, 0.00) 17.56%, rgba(237, 47, 161, 0.32) 112.15%),
    linear-gradient(0deg, #FFEEDE 0%, #FFEEDE 100%), #FCDCFF;

    .vz-personal-discount-promo-card {
      &-title {
        font-size: 19px;
        line-height: $fs-26;
        text-transform: uppercase;
        font-weight: 900;
        order: 0;
      }

      &-path {
        font-size: 16px;
        font-weight: bolder;
        order: 3;
        z-index: 10;
        align-self: flex-start;
        margin-left: 63px;
        margin-bottom: 10px;
        text-align: left;

        .vz-icon {
          color: $primary;
        }
      }

      &-value {
        display: flex;
        align-items: center;
        order: 1;
        padding: 17px 16px 17px 78px;
        margin-bottom: -15px;
        line-height: 1;
        font-size: 20px;
        color: $pure-white;
        background: url("/svg/components/discount-promo/discount-value.svg");
        width: 220px;
        height: 70px;
        font-weight: 900;
        text-transform: uppercase;

        &-empty {
          display: none;
        }
        &-value {
          order: 1;
        }
        &-type {
          order: 2;
        }
        &-title {
          order: 0;
          margin-right: 5px;
        }
      }
    }

    .vz-button {
      order: 3;
      width: 100%;
      color: $font-dark;
      font-weight: 500;
      background-color: transparent;
      border-color: $font-dark;
      border-width: 2px;
    }

    @media screen and (max-width: $pa-md) {
      .vz-personal-discount-promo-card {
        &-title {
          font-size: 16px;
          line-height: 20px;
        }
        &-value {
          background: url("/svg/components/discount-promo/discount-value-sm.svg");
          width: 147px;
          height: 84px;
          padding: 20px 10px 17px 67px;
          flex-wrap: wrap;
          margin-bottom: unset;

          &-title {
            font-size: 16px;
            line-height: 26px;
          }
          &-value {
            font-size: 36px;
            line-height: 30px;
            margin-left: 5px;
          }
          &-type {
            font-size: 26px;
            margin-top: auto;
          }
        }
        &-path {
          margin-left: 38px;
        }
      }
    }
  }

  &-8 {
    align-items: center;
    text-align: center;border-radius: 6px;
    background: linear-gradient(143deg, rgba(47, 226, 237, 0.40) 0%, rgba(47, 226, 237, 0.00) 100%), #FCDCFF;

    .vz-personal-discount-promo-card {
      &-title {
        font-size: 19px;
        line-height: $fs-26;
        text-transform: uppercase;
        font-weight: 900;
        order: 0;
      }

      &-path {
        font-size: 16px;
        font-weight: bolder;
        order: 3;
        z-index: 10;
        align-self: flex-start;
        margin-left: 63px;
        margin-bottom: 10px;
        text-align: left;

        .vz-icon {
          color: $primary;
        }
      }

      &-value {
        display: flex;
        align-items: center;
        order: 1;
        padding: 17px 16px 17px 78px;
        margin-bottom: -15px;
        line-height: 1;
        font-size: 20px;
        color: $pure-white;
        background: url("/svg/components/discount-promo/discount-value-1.svg");
        width: 220px;
        height: 70px;
        font-weight: 900;
        text-transform: uppercase;

        &-empty {
          display: none;
        }
        &-value {
          order: 1;
        }
        &-type {
          order: 2;
        }
        &-title {
          order: 0;
          margin-right: 5px;
        }
      }
    }

    .vz-button {
      order: 3;
      width: 100%;
      font-weight: 500;
      background-color: transparent;
      border-color: $primary;
      border-width: 2px;
    }

    @media screen and (max-width: $pa-md) {
      .vz-personal-discount-promo-card {
        &-title {
          font-size: 16px;
          line-height: 20px;
        }
        &-value {
          background: url("/svg/components/discount-promo/discount-value-1-sm.svg");
          width: 147px;
          height: 84px;
          padding: 20px 10px 17px 67px;
          flex-wrap: wrap;
          margin-bottom: unset;

          &-title {
            font-size: 16px;
            line-height: 26px;
          }
          &-value {
            font-size: 36px;
            line-height: 30px;
            margin-left: 5px;
          }
          &-type {
            font-size: 26px;
            margin-top: auto;
          }
        }
        &-path {
          margin-left: 38px;
        }
      }
    }
  }

  @media screen and (max-width: $pa-md) {
    padding: 10px;
    max-width: 169px;
  }

  @media screen and (max-width: $pa-sm) {
    display: none;
  }
}
</style>
