import { WorkSpaceItem } from '~/types/components/personal/header';

export const vzWorkSpaceMenu: WorkSpaceItem[] = [
  {
    name: 'order',
    title: 'Оформить заказ',
    to: { name: 'personal-order-create' },
    icon: '/svg/calculator.svg',
    smOnly: true
  },
  {
    name: 'events',
    title: 'События',
    to: { name: 'personal' },
    icon: '/svg/icon/vz-events.svg'
  },
  {
    name: 'orders',
    title: 'Заказы',
    to: { name: 'personal-orders-tab' },
    icon: '/svg/icon/vz-board.svg'
  },
  // {
  //   name: 'orders',
  //   title: 'Заказы',
  //   to: { name: 'personal-orders-tab' },
  //   icon: '/svg/icon/vz-board.svg',
  //   children: [
  //     {
  //       name: 'orders-list',
  //       title: 'Список',
  //       to: { name: 'personal-orders-tab' },
  //       icon: '/svg/icon/vz-board.svg',
  //     },
  //     {
  //       name: 'orders-templates',
  //       title: 'Шаблоны',
  //       to: { name: 'personal-orders-templates' },
  //       icon: '/svg/icon/vz-board.svg',
  //     },
  //     {
  //       name: 'import',
  //       title: 'Импорт',
  //       to: { name: 'personal-import' },
  //       icon: '/svg/icon/vz-import.svg'
  //     },
  //   ]
  // },
  {
    name: 'orders-templates',
    title: 'Шаблоны',
    to: { name: 'personal-orders-templates' },
    icon: '/svg/icon/vz-wysiwyg.svg'
  },
  {
    name: 'contractors',
    title: 'Контрагенты',
    to: { name: 'personal-contractors' },
    icon: '/svg/vz-person.svg'
  },
  {
    name: 'import',
    title: 'Импорт',
    to: { name: 'personal-import' },
    icon: '/svg/icon/vz-import.svg'
  },
  {
    name: 'bonus',
    title: 'Бонусы и задания',
    shortTitle: 'Бонусы',
    to: { name: 'personal-bonus-tab', params: { tab: 'task' } },
    icon: '/svg/icon/vz-star.svg'
  },
  {
    name: 'discount',
    title: 'Персональные скидки',
    shortTitle: 'Скидки',
    to: { name: 'personal-discount' },
    icon: '/svg/icon/vz-gift.svg',
    hidden: true
  },
  {
    name: 'telegram',
    title: 'Telegram',
    to:  { name: 'telegram' },
    icon: '/svg/icon/vz-telegram.svg',
    target: '_blank',
  },
  {
    name: 'settings',
    title: 'Настройки',
    to: { name: 'personal-account' },
    icon: '/svg/vz-gear.svg',
    smOnly: true
  },
  {
    name: 'exit',
    title: 'Выйти',
    to: { name: 'personal-logout' },
    icon: '/svg/icon/vz-exit.svg'
  }
]
