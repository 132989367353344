<template>
  <vz-icon
    ref="button"
    big
    name="alert"
    variant="primary"
    class="vz-personal-header-navbar-icon"
    title="Сообщить об ошибке"
    size="24"
    @click="openModal"
  />
</template>

<script setup lang="ts">
import VzIcon from '~/components/ui-kit/vz-icon.vue';

const { $showDialog } = useNuxtApp()

function openModal(): void {
  $showDialog({ name: 'personal-feedback' })
}
</script>