<template>
  <div class="vz-geo-position">
    <div
      class="vz-geo-position-title"
      @click="openVzRegionModal"
    >
      <vz-icon
        name="map-marker"
        variant="white"
        size="16"
        padding="0"
      />
      <span class="vz-geo-position-title-text">{{ chosenCityName }}</span>
      <vz-icon
        name="chevron-down"
        variant="white"
        padding="0"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {useCityStore} from '~/stores/city';
import {storeToRefs} from 'pinia';
const {$showDialog} = useNuxtApp()

const { currentCity } = storeToRefs(useCityStore())

const chosenCityName = computed(() => {
  return currentCity.value.name
})

function openVzRegionModal(): void {
  $showDialog({ name: 'region' })
}
</script>
