<template>
  <div class="vz-personal">
    <NuxtLoadingIndicator />
    <vz-personal-header :class="menuOpenClass" @toggle="onMobileMenuOpen" />
    <div class="vz-personal-container">
      <div class="vz-personal-sidebar">
        <div class="vz-personal-sidebar-container">
          <nuxt-link :to="'/personal/order/create/'" class="vz-card-light-shadow vz-personal-sidebar-link" :class="menuOpenClass">
            <span class="mdi mdi-plus vz-personal-sidebar-link-plus" />
            <span class="vz-personal-sidebar-link-text">Оформить заказ</span>
          </nuxt-link>
          <vz-personal-menu @toggle="onMobileMenuOpen" />
        </div>
      </div>
      <div class="vz-personal-content" :class="menuOpenClass">
        <div class="vz-personal-content-wrapper" :class="menuOpenClass">
          <slot />
        </div>
      </div>
    </div>
    <vz-personal-footer :class="menuOpenClass" />
    <vz-offer-modal />
    <vz-backend-stub-modal />
    <vz-overlay :permanent="!backendAvailable" />
    <vz-request-document-modal />
    <vz-request-claim-modal />
  </div>
</template>

<script setup lang="ts">
import { useBackendStore } from '~/stores/backend'
import { useLayoutStore } from '~/stores/personal/layout'
import { storeToRefs } from 'pinia'
import { VzClass } from '~/types/types'
import VzPersonalMenu from '~/components/personal/header/vz-personal-menu.vue'
import VzPersonalHeader from '~/components/personal/header/vz-personal-header.vue';
import VzPersonalFooter from '~/components/personal/vz-personal-footer.vue';
import VzOverlay from '~/components/ui-kit/vz-overlay.vue';
import VzOfferModal from '~/components/modals/vz-offer-modal.vue';
import VzBackendStubModal from '~/components/modals/vz-backend-stub-modal.vue'
import VzRequestDocumentModal from '~/components/modals/vz-request-document-modal.vue';
import VzRequestClaimModal from '~/components/modals/vz-request-claim-modal.vue'
import { useSeoStore } from "~/stores/seo";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";

const seoStore = useSeoStore()
const { backendAvailable } = storeToRefs(useBackendStore())
const { menuCollapsed } = storeToRefs(useLayoutStore())
const { setCollapsedMenu } = useLayoutStore()

const { $showDialog, $hideDialog, $showToast } = useNuxtApp()

useHead({
  title: seoStore.seo?.seo?.title || 'Транспортная компания «Возовоз»',
  meta: seoStore.seo?.seo?.meta,
  link: seoStore.seo?.seo?.canonical ? [{ rel: 'canonical', href: `${seoStore.seo?.seo?.canonical}` }] : undefined,
})

const menuOpenClass = computed((): VzClass => {
  return menuCollapsed.value  ? 'menu-open' : ''
})

onMounted(() => {
  if (!backendAvailable.value) {
    nextTick(() => {
      $showDialog({ name: 'vz-backend-stub-modal' })
    })
  }
})

watch(() => backendAvailable.value, () => {
  if (backendAvailable.value) {
    $hideDialog({ name: 'vz-backend-stub-modal' })
  } else {
    $showDialog({ name: 'vz-backend-stub-modal' })
  }
})

function onMobileMenuOpen(): void {
  setCollapsedMenu(!menuCollapsed.value)
}

//Firebase push notification
/*
if (await isSupported()) {
  const config = useRuntimeConfig()
  const app = initializeApp(config.public.FIREBASE_CONFIG);
  const messaging = getMessaging()

  onMessage(messaging, (payload) => {
    $showToast({ title: payload.notification?.title, text: payload.notification?.body })
    console.log('Message received: ', payload)
  })

  if (!localStorage.getItem('firebase-token')) {
    getToken(messaging, { vapidKey: config.public.FIREBASE_VAPID_KEY }).then((currentToken) => {
      if (currentToken) {
        console.log('Token is:', currentToken)
        localStorage.setItem('firebase-token', currentToken)
      } else {
        console.log('No registration token available. Request permission to generate one.')
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    })
  }
}*/
</script>
